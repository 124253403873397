/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/dayjs@1.11.3/dayjs.min.js
 * - /npm/dayjs@1.11.3/plugin/timezone.min.js
 * - /npm/dayjs@1.11.3/plugin/utc.min.js
 * - /npm/dayjs@1.11.3/plugin/relativeTime.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
